<template>
  <div class="body">
    <div class="top"></div>
    <div class="center">
      <div class="nav left">
        <div class="item" v-for="(item, index) in leftNav" :key="index" @click="navTo(item)">{{ item.name }}</div>
      </div>
      <div class="middle">
        <div class="no-click">
          <div class="star"></div>
          <div class="line"></div>
          <div class="line2"></div>
          <div class="pie"></div>
          <canvas id="canvas"></canvas>
          <div class="btn btn1">
            <div>科技</div>
            <div>环境</div>
          </div>
          <div class="btn btn2">
            <div>
              <span class="number">16</span>所
            </div>
            <div>院所</div>
          </div>
          <div class="btn btn3">
            <div>
              <span class="number">21</span>所
            </div>
            <div>高校</div>
          </div>
          <div class="btn btn4">
            <div>
              <span class="number">524</span>家
            </div>
            <div>企业</div>
          </div>
          <div class="btn btn5">
            <div>
              <span class="number">6385</span>人
            </div>
            <div>人才</div>
          </div>
        </div>
      </div>
      <div class="nav right">
        <div class="item" v-for="(item, index) in rightNav" :key="index" @click="navTo(item)">{{ item.name }}</div>
      </div>
    </div>
    <div class="footer">
      <div class="vr" @click="goToVr">
        <img src="../../static/images/boot/vr.png" class="img" />
      </div>
      <div class="footer-text">
        <div class="left">
          <!-- <img src="../static/images/logo1@2x.png" class="logo"/> -->
          <div class="name">
            <!-- <div class="h2 xieti">云上科技城</div> -->
            <img src="../../static/images/name@2x.png" class="name-yun" />
            <div class="h4">中国 · 绵阳</div>
          </div>
        </div>
        <div class="right h4" style="padding-top: 30px;">
          友情链接：
          <span style="margin:0 20px;">
            <a class="link" href="https://www.most.gov.cn/index.html">科技部</a>
          </span>
          <span style="margin:0 20px;">
            <a class="link" href="http://www.caict.ac.cn/">中国信息通信研究院</a>
          </span>
          <span style="margin:0 20px;">
            <a class="link" href="https://kjt.sc.gov.cn/kjt/index.shtml">四川省科技厅</a>
          </span>
          <br />
          <a class="link" href="https://beian.miit.gov.cn/">备案号： 蜀ICP备2022001611号-2</a>
          <br />

          <!-- <span>版权所有@2022｜绵阳新投实业有限公司</span> -->
          <br />
        </div>
      </div>
    </div>
  </div>
</template>

<script>


export default {
  name: 'Home',
  components: {},
  data() {
    return {
      leftNav: [
        {
          name: '科技大市场',
          path: '/technologyMarket'
        },
        {
          name: '仪器共享',
          path: '/sharedInstrument'
        },
        {
          name: '科技金融',
          path: '/technologyFinance'
        },
        {
          name: '知识产权',
          path: '/intellectualProperty'
        }
      ],
      rightNav: [
        {
          name: '数字服务',
          path: '/digitalServices'
        },
        {
          name: '云上招引',
          path: '/solicitationCloud'
        },
        {
          name: '云上科博会',
          path: '/technologyExpoCloud'
        },
        {
          name: '政策大厅',
          path: '/policyHall'
        }
      ],
      canvas: null,
      ctx: null,
      text: 'JKALDJQOWE019284019JIJRDJAIDKADJKSKLAKD',
      len: 20,
      num: 50,
      arr: [],

    }
  },
  created() {
    // 调用工具方法示例
    this.$nextTick(() => {
      this.canvas = document.querySelector("canvas");
      this.ctx = this.canvas.getContext('2d');
      this.initArray();
      setInterval(() => {
        this.ctx.clearRect(0, 0, 430, 360);
        this.move();
        this.txt();
      }, 50)
    })

  },
  mounted() {

  },
  methods: {
    initArray() {
      for (let i = 0; i < this.num; i++) {
        this.arr.push({
          str: [],
          x: parseInt(430 * Math.random()),
          y: parseInt(300 * Math.random())
        })
      }
    },
    txt() {
      for (let i = 0; i < this.num; i++) {
        let letter = this.arr[i];
        letter.str = [];
        for (let j = 0; j < this.len; j++) {
          letter.str.push(this.text[Math.floor(Math.random() * this.text.length)])
        }
        for (let k = 0; k < this.len; k++) {
          if (k == this.len - 1) {
            this.ctx.fillStyle = '#00c9f8'
          } else {
            this.ctx.fillStyle = `rgba(0, 201, 248, ${k * 0.25})`
          }
          this.ctx.font = '10px'
          // this.ctx.translate(0,0)
          // this.ctx.rotate(Math.PI/2)
          this.ctx.fillText(letter.str[k], letter.x, letter.y + k * 15)
        }
      }
      this.move()
    },
    move() {
      for (let i = 0; i < this.num; i++) {
        this.arr[i].y += 1; // 
        if (this.arr[i].y > 320) {
          this.arr[i] = {
            str: [],
            x: parseInt(430 * Math.random()),
            y: parseInt(300 * Math.random())
          }
        }
      }
    },
    navTo(item) {
      if (item.path == '/technologyMarket') {
        window.location.href = "https://mycloud-univ.com/tech-achievements"
      }
      else {
        let a = setTimeout(() => {
          this.$router.push(item.path)
          a = null;
          sessionStorage.setItem("currentRoute", item.path)
        }, 300)
      }

    },
    goToVr() {
      window.location.href = "http://case.tusuivr.com/home/preview/view/pano_id/578"

    }
  }
}
</script>

<style scoped lang="less">
.body {
  position: relative;
  background: #0b184b;
  box-sizing: border-box;
  width: 100%;
  min-width: 1440px;
  background-image: url("../../static/images/boot/bg@2x.png");
  min-height: 100vh;
  background-size: 100% 100%;

  .page-title {
    display: block;
    margin: 30px auto;
  }

  .center {
    margin: 0 auto;
    padding-top: 120px;
    padding-bottom: 140px;
    color: #fff;
    width: 1440px;
    font-size: 24px;
    position: relative;
    height: 748px;
    overflow: hidden;

    .middle {
      transform: scale(1.1);
      position: absolute;
      left: 100px;
      right: 100px;
      top: 0;
      height: 900px;
      background-image: url("../../static/images/boot/m-b@2x.png");
      background-size: 100% auto;
      background-position: center bottom;
      background-repeat: no-repeat;

      .star {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        background-image: url("../../static/images/boot/star@2x.png");
        background-size: 100% auto;
        background-position: center bottom;
        background-repeat: no-repeat;
      }

      .line {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        background-image: url("../../static/images/boot/line@2x.png");
        background-size: 500px 560px;
        background-position: center 220px;
        background-repeat: no-repeat;
        // animation: rotate 6s linear infinite;
      }

      .line2 {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        background-image: url("../../static/images/boot/line2@2x.png");
        background-size: 600px 560px;
        background-position: center 220px;
        background-repeat: no-repeat;
      }

      .pie {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        background-image: url("../../static/images/boot/b-c.png");
        background-size: 500px auto;
        background-position: center 220px;
        background-repeat: no-repeat;
        animation: mill 5s linear infinite;
      }

      #canvas {
        position: absolute;
        bottom: 200px;
        left: 409px;
        width: 430px;
        height: 360px;
      }

      .num {
        position: absolute;
        bottom: 224px;
        left: 329px;
        width: auto;
        height: 343px;
      }

      .num2 {
        left: 409px;
      }

      .num7 {
        left: 679px;
        bottom: 268px;
      }

      .num3 {
        left: 520px;
        bottom: 268px;
      }

      .num4 {
        left: 579px;
        bottom: 244px;
      }

      .num5 {
        left: 449px;
        bottom: 324px;
      }

      .num6 {
        left: 706px;
        bottom: 190px;
      }

      .btn {
        position: absolute;
        top: 114px;
        left: 542px;
        width: 150px;
        height: 150px;
        background-image: url("../../static/images/boot/btn@2x.png");
        background-size: 100% auto;
        text-align: center;
        cursor: pointer;
        line-height: 1.4;
        box-sizing: border-box;
        padding-top: 44px;
        font-size: 18px;
        /*动画名称*/
        animation-duration: 0.5s;
        /*设置秒数*/
        animation-timing-function: linear;
        /*速度曲线*/
        animation-iteration-count: infinite;
        /*播放次数*/
        animation-direction: alternate;
        /*逆向播放*/
        animation-play-state: running;

        /*正在运行*/
        .number {
          font-size: 36px;
          color: #c3ffff;
        }
      }

      .btn1 {
        padding-top: 50px;
        font-size: 22px;
        animation-name: beat1;
      }

      .btn2 {
        top: 255px;
        left: 295px;
        animation-name: beat2;
        animation-duration: 0.6s;
      }

      .btn3 {
        top: 300px;
        left: 442px;
        animation-name: beat3;
        animation-duration: 1s;
      }

      .btn4 {
        top: 300px;
        left: 654px;
        animation-name: beat3;
        animation-duration: 1.4s;
      }

      .btn5 {
        top: 255px;
        left: 810px;
        animation-name: beat2;
        animation-duration: 0.9s;
      }
    }

    .left {
      left: 10px;
    }

    .right {
      right: 10px;
    }

    .nav {
      position: absolute;
      top: 300px;
      transform: scale(1.1);
      z-index: 99;

      .item {
        width: 197px;
        height: 75px;
        line-height: 75px;
        text-align: center;
        margin: 30px 0;
        background-image: url("../../static/images/boot/a@2x.png");
        background-size: 100% auto;
        cursor: pointer;
      }

      .item:active {
        transform: scale(0.9);
      }
    }
  }

  .top {
    background-image: url("../../static/images/boot/top@2x.png");
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 140px;
    background-size: 100% auto;
    background-position: 0 top;
    background-repeat: no-repeat;
  }

  .footer {
    background-image: url("../../static/images/boot/footer@2x.png");
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 400px;
    background-size: 100% auto;
    background-position: 0 bottom;
    background-repeat: no-repeat;
    z-index: 1;

    .vr {
      position: absolute;
      left: 50%;
      bottom: 100px;
      transform: translateX(-50%);
      width: 200px;
      height: 55px;
      z-index: 99999;
      // background-image: url('../../static/images/boot/vr.png');
      background-image: url("../../static/vrkeji-bg.png");
      background-size: 100% 100%;
      background-position: center center;
      background-repeat: no-repeat;
      cursor: pointer;
      z-index: 100;
      display: flex;
      align-items: center;
      justify-content: center;

      .img {
        width: 80%;
        height: 60%;
        object-fit: contain;
      }
    }

    .footer-text {
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);
      display: flex;
      justify-content: center;
      color: #fff;
      text-shadow: 1px 1px 1px rgb(48, 43, 44);

      .left {
        display: flex;
        align-items: center;

        .logo {
          width: 117px;
          height: 46px;
        }

        .name {
          text-align: center;
          padding-left: 10px;
          padding-right: 20px;
          border-right: 2px solid #fff;
          margin-right: 5px;

          .h2 {
            font-size: 20px;
            font-family: YouSheBiaoTiHei;
          }

          .h4 {
            font-size: 16px;
            text-align: right;
          }

          .name-yun {
            width: 140px;
            height: auto;
          }
        }
      }

      .right {
        padding-left: 20px;

        .link {
          line-height: 40px;
          color: #fff;
        }
      }
    }
  }
}

@media screen and (max-width: 1280px) {}

@keyframes beat1 {
  0% {
    top: 114px;
  }

  100% {
    top: 118px;
  }
}

@keyframes beat2 {
  0% {
    top: 255px;
  }

  100% {
    top: 257px;
  }
}

@keyframes beat3 {
  0% {
    top: 300px;
  }

  100% {
    top: 303px;
  }
}

@keyframes mill {
  0% {
    transform: scale(0.95);
  }

  50% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.95);
  }
}
</style>
